<template>
	<div
		class="full-height flex-column"
	>
		<div>
			<h6 class="text-left">정산 구분</h6>
			<div class="top-line-identify pa-10 text-left box bg-eee">
				<button
					class="pa-5-10 box"
					:class="search.type == '0' ? 'bg-identify' : 'bg-white'"
					@click="search.type = '0'; getSearch()"
				>일간</button>
				<button
					slot="add"
					class="pa-5-10 box mr-10"
					:class="search.type == '1' ? 'bg-identify' : 'bg-white'"
					@click="search.type = '1'; getSearch()"
				>월간</button>

				<button
					v-for="(item, index) in codes.settlement_agency_type"
					:key="'settlement_agency_type_' + index"
					class="pa-5-10 border-right"
					@click="search.searchType = item.code; getData()"
					:class="search.searchType == item.code ? 'bg-identify' : 'bg-white'"
				>{{ item.name }}</button>
			</div>
		</div>
		<div class="mt-10">
			<h6 class="text-left">매출 합계</h6>
			<table class="table top-line-identify">
				<thead>
				<tr>
					<th>매출 시작일</th>
					<th>매출 종료일</th>
					<th>정산 기준</th>
					<th>승인금액</th>
					<th>정산금액</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>{{ item.fromDate }}</td>
					<td>{{ item.toDate }}</td>
					<td>{{ item.calMonth }}</td>
					<td>{{ summary.approvalAmount }}</td>
					<td>{{ summary.calAmount }}</td>
				</tr>
				</tbody>
			</table>
		</div>

		<div class="mt-10 full-height flex-column overflow-y-auto">
			<h6 class="justify-space-between">
				매출 내역

				<div>
					<button
						class="box mr-10 pa-4-10 size-px-12"
						@click="toExcel"
					><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">엑셀 다운로드</span></button>
					<select
						class="pa-5-10  size-px-12"
						v-model="search.size"
						@change="getSearch()"
					>
						<option
							v-for="cnt in codes.list_cnt"
							:key="'cnt_' + cnt"
							:value="cnt"
						>{{ cnt }} 건씩 보기</option>
					</select>
				</div>
			</h6>

			<table
				v-if="items.length > 0"
				class="table top-line-identify"
			>
				<colgroup>
					<col width="60px" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
				</colgroup>
				<thead>
				<tr>
					<th>NO</th>
					<th>정산기준일</th>
					<th>영업점</th>
					<th>결제건수</th>
					<th>승인금액</th>

					<th>차감건수</th>
					<th>차감금액</th>
					<th>수수료율</th>
					<th>정산금액</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in item_list"
					:key="'item_' + index"
				>
					<td>{{ item.idx }}</td>
					<td>{{ item.calDate }}</td>
					<td class="text-left">{{ item.branchName}}</td>
					<td>{{ item.count }}건</td>
					<td class="text-right">{{ item.approvalAmount }}</td>

					<td>{{ item.deductionCount }}건</td>
					<td class="text-right">{{ item.deductionAmount}}원</td>
					<td>{{ item.fee}}</td>
					<td class="text-right">{{ item.calAmount }}원</td>
				</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center  top-line-identify"
			>
				<div class="text-center">
					<v-icon
						class="size-px-48 "
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>
		</div>

		<pagination
			:options="search"

			class="mt-auto"
			@click="getSearch"
		></pagination>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"

			@finish="is_excel = !is_excel"
		></Excel>
	</div>
</template>

<script>

import Pagination from "@/components/Pagination";
import { Axios } from "@/resources/axios/axios";
import { date } from "@/resources/date/date";
import {codes} from "@/resources/config/codes";
import Excel from "@/components/Excel";

export default {
	name: 'SettlementAgencyListDetail'
	,components: {Excel, Pagination }
	,props: ['item', 'user', 'type']
	,data: function(){
		return {
			Axios: Axios
			,date: date
			,codes: codes
			,items: []
			,search: {
				page: 1
				,size: 10
				, adminIdx: this.item.adminIdx
				, date: this.item.calMonth
				, searchType: ''
				, type: this.type
			}
			,summary: {}
			,is_excel: false
			,excel_data: {
				name: '영업점 정산 상세 내역'
				,header: [
					{ key: 0, name: 'NO', column: 'idx'}
					,{ key: 0, name: '정산기준일', column: 'calDate'}
					,{ key: 0, name: '영업점', column: 'branchName'}
					,{ key: 0, name: '결제건수', column: 'count'}
					,{ key: 0, name: '승인금액', column: 'approvalAmount'}
					,{ key: 0, name: '차감건수', column: 'deductionCount'}
					,{ key: 0, name: '차감금액', column: 'deductionAmount'}
					,{ key: 0, name: '수수료율', column: 'fee'}
					,{ key: 0, name: '정산금액', column: 'calAmount'}
				]
				,content: null
			}
		}
	}
	,computed: {
		item_list: function(){

			return this.items.filter(function(item){
				return item
			})
		}
	}
	,methods: {
		getData: async function(){

			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'calculate/branch/history/detail'
					,data: this.search
				})

				if(result.success){
					this.items = result.data.tableList.data
					this.$set(this.search, 'total_count', result.data.tableList.totalCount)
					this.summary = result.data.statistics ? result.data.statistics : {}
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,getSearch: function(page){
			if(page){
				this.search.page = page
			}

			this.getData()
		}

		,toExcel: function(){
			this.is_excel = false
			this.excel_data.content = this.items
			this.is_excel = true
		}
	}
	,created() {
		this.getData()
	}
}
</script>